@import url("../base.css");

.footer{
    width:100%;
    height:497px;
    background-color:#eeeeee;
    border-left-color: #dddddd;
    border-right-color: #dddddd;
}

.footer-contents{
    width:964px;
    height:100%;
}
.footer-contents-info {
    width: 480px;
    float: left;
    padding: 80px 0;
}

.footer-text{
    width:100%;
    float: left;
}

.copyright{
    width:100%;
    float:left;
    padding-bottom: 35px;
}

.user{
    float:left;
    width:100% !important;
}

/* .user{
    width:47%;
    float:left;
    
} */

.user h5 {
    float: left;
    padding-right: 30px;
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #191919 ;
    opacity: 1;
}

h6{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:500; 
    color:#ffffff;
    font-size:14px;
    opacity: 0.5;
    padding-top:60px;
    
}
.adress_and_moditt{
    width:100%;
    float: left;
    height: 100%;
    padding: 35px 0;
}

.adress{
    width:217px;
    float: left;
    padding-left: 35px;
}

.adress_moditt{
    width:161px;
    float: left;
}

.adress h5{
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #9a9a9a;
}
.sns_link {
    width: 100%;
    float: left;
}

h5{
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #9a9a9a;
}

hr{
    opacity: 0.2;
    width:100%;
    background-color: #ffffff;
}


.opacity{
    opacity: 0.5 !important;
    line-height: 1.67 !important; 
    font-weight: 500 !important;
}

/* display : none */
.mobile-copyright {
    display:none;
}

.snsBox {
    width: 40px;
    height: 40px;
    border-radius: 12.5px;
    background-color: #9a9a9a;
    float: left;
    cursor: pointer;

}
.snsBox:hover {

}

.sns {
    width: 40px;
    height: 40px;
    border-radius: 12.5px;
    /*margin: 8px 8px 8px 8px;*/
    background-repeat: no-repeat;
    background-position: center;
}
.padding_left {
    margin-left: 20px;
}

.instagram {
    background-image: url("../../img_update/insta-btn.svg");
}
.blog {
    background-image: url("../../img_update/blog-btn.svg");
}
.instagram:hover {
    background-image: url("../../img_update/insta-btn-70.svg");
}
.blog:hover {
    background-image: url("../../img_update/blog-btn-hover.svg");
}