@import url("../base.css");

.section4{
    width:100%;
    height:867px;
    background-color:#fafafa;
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
}

.sectionimg3{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 0px;

}

.h1{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:500; 
}