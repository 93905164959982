  /* Device = Desktop = 1024px to 1439px */
  @media (min-width: 1024px) and (max-width: 1439px) {
    .size{
        max-width: 1439px;
        width: 100%;
        height: 100%;
    }
      .section-contents{
          width:964px;
          height:100%;
      }
      .section-contents-section1 {
          width: 964px;
          /*height: 100%;*/
          padding: 0;
      }
      .contents-left-section1 {
          float: left;
          /*padding-left: 30px;*/
      }
      .desktop {
          float: left;
          width: 100%;
          height: 100%;
      }
      .navbar_contents {
          border-left: 0 !important;
          border-right: 0 !important;
          border-top: 0 !important;
      }

      .section1, .section2, .section3, .section4, .section5, .section6, .section7, footer {
          border-left: 0 !important;
          border-right: 0 !important;
      }
  }

  @media (min-width: 1440px) and (max-width: 1920px) {
      .navbar_contents {
          border-left: 0 !important;
          border-right: 0 !important;
          border-top: 0 !important;
      }

      .section1, .section2, .section3, .section4, .section5, .section6, .section7, footer {
          border-left: 0 !important;
          border-right: 0 !important;
      }
  }