@import url("../base.css");

.footer{
    width:100%;
    height:100% !important;
    background-color:#eeeeee;
    border-left-color: #dddddd;
    border-right-color: #dddddd;
}
.footer-desktop {
    display: block;
    width: 100%;
    float: left;
}
.footer-mobile {
    display: none;
}
.footer-contents-info {
    width: 100%;
    float: left;
    padding: 118px 0 138px 0;
}
.footer-title-text {
    width: 100%;
    height: 24px;
    float: left;

    margin: 0 0 15px 0;
}
.footer-title-text h1 {
    font-family: NotoSansCJKkr;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #9a9a9a;
}
.footer-moditt {
    width: 100%;
    float: left;
}
.footer-moditt h2 {
    float: left;
    font-family: NotoSansCJKkr;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #9a9a9a;
}
.footer-moditt-h2 {
    border-right: 1px solid #9a9a9a;
    margin: 2px 4px 4px 5px;
    width: 1px;
    height: 9px;
    float: left;
}

.footer-text{
    width:100%;
    float: left;
    margin: 20px 0;
}

.copyright{
    width:100%;
    float:left;
    padding-bottom: 0;
}

.user{
    float:left;
    width:100% !important;
}

/* .user{
    width:47%;
    float:left;

} */

.user h5 {
    float: left;
    padding-right: 30px;
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #191919 ;
    opacity: 1;
}

h6{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:500;
    color:#ffffff;
    font-size:14px;
    opacity: 0.5;
    padding-top:60px;

}
.adress_and_moditt{
    width:100%;
    float: left;
    height: 100%;
    padding: 35px 0;
}

.adress{
    width:217px;
    float: left;
    padding-left: 35px;
}

.adress_moditt{
    width:161px;
    float: left;
}

.adress h5{
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #9a9a9a;
}
.sns_link {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

h5{
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #9a9a9a;
}

hr{
    opacity: 0.2;
    width:100%;
    background-color: #ffffff;
}


.opacity{
    opacity: 0.5 !important;
    line-height: 1.67 !important;
    font-weight: 500 !important;
}

/* display : none */
.mobile-copyright {
    display:none;
}

.snsBox {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background-color: #9a9a9a;
    float: left;
    cursor: pointer;

}
.snsBox:hover {

}

.sns {
    width: 24px;
    height: 24px;
    /*border-radius: 12.5px;*/
    /*margin: 8px 8px 8px 8px;*/
    background-repeat: no-repeat;
    background-position: center;
}
.padding_left {
    margin-left: 20px;
}

.instagram {
    background-image: url("../../img_update/insta-btn.svg");
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    margin: 5px 5px;
}
.blog {
    background-image: url("../../img_update/blog-btn.svg");
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    margin: 5px 5px;
}
/*.instagram:hover {*/
/*    background-image: url("../../img_update/insta-btn-70.svg");*/
/*}*/
/*.blog:hover {*/
/*    background-image: url("../../img_update/blog-btn-hover.svg");*/
/*}*/