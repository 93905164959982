@import url("../base.css");

.section7{
    width:100%;
    height:593px;
    background-image: url('../../img_update/bg-3-desktop-1920.svg');
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
    background-repeat: no-repeat;
    background-position: center;
}

.size{
    max-width:1920px;
    width: 100%;
    height:100%;
}
.contents-top{
    width:100%;
}

.contents-mus{
    width: 100%;
    height:100%;
}
.contents-mus-text{
    text-align: center;
    padding-top:120px;
}

h4{
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
    padding: 35px 0 0 0;

}

.appicon{
    width:140px;
    height:140px;
    box-shadow: 0 4px 9px 0 rgba(162, 162, 162, 0.5);
    border-radius: 24px;

}

.contents-app-down{
    width:100%;
}

.contents-app-text{
    margin: 0 0 0 283px;
    padding-top: 50px;
    /*width: 681px;*/
    float: left;
    height: 100%;

}
.googleplay-bottom{
    width: 189px;
    height: 52px;
    /*margin: 50px 20px 0 283px;*/
    float: left;
}
.appstore-bottom{
    width: 189px;
    height: 52px;
    /*padding: 50px 283px 0 0;*/
    float: right;
}


.contents-bottom{
    width:100%;
    text-align:center;
}