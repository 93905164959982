@import url("../base.css");

.section5{
    width:100%;
    height:660px;
    background-color:#eeeeee;
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
}

.sectionimg4{
    width:360px;
    height:607px;
    padding:120px 0 140px 0;

}
.sectionimg5 {
    width:400px;
    height:400px;
    padding:120px 0 140px 0;
}