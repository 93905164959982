  /* Device = Tablets, Ipads , Screen = 768px to 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
    .lottieSize {
        width: 360px;
        height: 607px;
    }
    .size{
        /*max-width: 1439px;*/
        max-width:1023px !important;
        width: 100%;
        height: 100%;
    }
    .section-contents{
        width:708px;
        height:896px;
        padding: 120px 0 140px 0 ;
    }
    .section-contents-section1 {
        width: 100%;
        padding: 0 !important;
    }
    .section1 {
        height: 1331px;
        background-image: url('../../img_update/bg-1-tablet-1024.svg');
        border-left:0;
        border-right:0;
    }
    .contents-left {
        width: 100%;
    }
    .contents-left-section1 {
        margin: 0 30px 0 30px;
        float: left;
    }
    .appstore_button {
        float: right !important;
    }
    .sectionimg {
        margin-top: 160px;
        background-image: url("../../img_update/tablet@3x.png");
        /*background-size: 608px 760px;*/
        background-repeat: no-repeat;
        margin-top: 35px;
        margin-left: 135px;
    }
    .contents-right-section1 {
        height: 940px;
        width: 100%;
        overflow: auto;
        /*height: 100%;*/
        padding: 0 0 0 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        /*margin-left: 20px;*/

        /*height: 100%;*/
        /*right: 0;*/
    }
    .top-contetns {
        padding-top: 30px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #7f7f7f;
    }
    .top-app {
        padding-top: 30px;
    }
    .section2 {
        width: 100%;
        height: 1156px !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }
    .desktop, .mobile {
        display: none;
    }
    .tablet, .tablet_section1 {
        display: block;
    }
    .tablet .select-contents-type-box {
       width: 100%;
        height: 36px;
        float: left;

    }
    .tablet .contents-left {
        width: 100%;
        text-align: center;
    }
    .select-contents-padding {
        padding: 0 20px;
    }
    .contents-right {
        width: 100%;
        height: 100%;
    }
    .contents-text {
        padding-top: 0;
        text-align: center;
    }
    .contents-text h1 {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #ffffff;
    }
    .thumbs-up {
        width: 360px;
        height: 607px;
        padding: 35px 0 35px 0 !important;
    }
    .top-contetns-white {
        font-family: NotoSansCJKkr;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: center;
        color: #cccccc;
    }
    .select-contents-type{
        text-align: center;
        width: 260px;
        height: 100%;
        padding: 0 8px;
    }
    .section3 {
        width: 100%;
        height: 817px !important;
        border-right: 0 !important;
        border-left: 0 !important;
    }
    .section3 .section-contents {
        width: 708px;
        height: 100%;
        padding: 0 0 0 0;
    }
    .section4 {
        width: 100%;
        height: 1085px !important;
        border-right: 0 !important;
        border-left: 0 !important;

    }
    .section4 .section-contents {
        padding: 0 0 0 0;
        text-align: center;
    }
    .contents-text-black h1 {
        text-align: center;
    }
    .contents-text-black {
        padding: 120px 0 0 0;
        text-align: center;
    }
    .section5 {
        width: 100%;
        height: 878px !important;
        border-right: 0 !important;
        border-left: 0 !important;
    }
    .section5 .section-contents {
        padding: 0 0 0 0;
        height: 878px !important;
    }
    .section6 {
        height: 364px !important;
        border-right: 0 !important;
        border-left: 0 !important;
    }
    .section6 .section-contents {
        width: 708px;
        height: 100%;
        padding: 0 0;
    }
    .section6 .contents-left {
        display: none;
    }
    .contents-coupon {
        padding: 120px 0 140px 0;
        text-align: center;
        width: 100%;
        /*width: 708px;*/
        height: 104px;
    }
    .contents-coupon tablet {
        text-align: center;
        width: 100%;
    }
    .contents-coupon h1 {
        text-align: center;
    }

    .section7 {
        border-right: 0 !important;
        border-left: 0 !important;
    }
    .section7 .section-contents {
        padding: 0 0 0 0;
    }
    .contents-bottom {
        width: 100%;
        height: 204px;
        display: inline-block;
    }
    .contents-app-down {
        text-align: center;
        width: 398px !important;
        height: 100%;
    }
    .contents-app-text {
        width: 100%;
        padding-top: 50px;
        height: 100%;
        margin: 0 !important;
    }
    .footer {
        border-left: 0 !important;
        border-right: 0 !important;
    }
    .footer-contents-info {
        width: 100%;
        padding: 80px 30px !important;
    }
    .appstore-bottom, .googleplay-bottom {
        padding : 0 !important;
    }
    .footer-contents {
        width: 100% !important;
        height: 395px !important;
    }


    @import url("../base.css");
    @import url('https://fonts.googleapis.com/css?family=Noto+Serif+KR:200,300,400,500,600,700,900&subset=korean');

    .wrap{
        width:100%;
        height:100%;
        padding-bottom: 60px;
    }


    .header{
        width:100%;
        height: 60px;
        background-color:#fafafa;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700;
        text-align: center;
        border-left: 0;
        border-right: 0;
        position: fixed !important;
        display:flex;
        z-index :99999;
    }


    .mus_logo{
        float:center;
        width:81px;
        height:30px;
        margin:0 auto;
        text-align: center;
        padding: 15px 0px 15px 30px;
        cursor: pointer;
    }

    .logo{
        float:left;
    }
    .navbar_contents {
        width: 100%;
        max-width:1439px;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }
    .navbar{
        /*width:1920px;*/
        max-width: 1023px;
        height:100%;
        margin:0 auto;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700;
        /*float:right;*/
        text-align:center;



    }
    .menu{
        height:100%;
        margin:0 auto;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700;
        float:right;
        text-align:center;
    }

    .menu li{
        float:left;
        list-style:none;
        padding: 20px 40px 20px 0px;
        font-size: 14px;
        font-family: 'NotoSansCJKkr', sans-serif;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #191919;
    }

    .header-nav{
        float:right;
        font-size:16px;
        color: #000000;
        margin:0 auto;
        padding: 0.5%;
        font-family: 'Noto Serif KR', sans-serif;
        font-weight:700;
        padding-right:2%;
    }

  }