/* font - face */
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+KR:200,300,400,500,600,700,900&subset=korean');
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');

/* 기본 값 초기화 */
  *{
    margin:0 auto;
    padding:0;
  }

  *:focus { 
    outline:none; 
  }  

  h1,h2,h3,h4,h5,h6 {
    font-size:100% 
  }
/* 모바일 수평 스크롤 금지 */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  overflow: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
}
.about, .pay, .master, .menulist, .menubtn, .cross, .mobile_app_download, .event_down{
  -webkit-tap-highlight-color : rgba(0,0,0,0);
}
.menulist:focus,
.about:focus,
.pay:focus,
.master:focus,
.button:focus{
    outline: none !important;
}

/* 좌우 border */
.solid {
  border:0.5px 
  solid #eeeeee;
  border-top-style: none;
  border-bottom-style: none;
}

.lottieSize {
  width: 360px;
  height:607px;
}
.lotteSize4 {
  width: 400px;
  height: 400px;
}

.section1 .contents {
  width: 427px;
  height: 90%;
  float: left;
}

.section-contents{
  width:964px;
  height:100%;
}
.section-contents-section1 {
  max-width: 1224px;
  width: 100%;
  /*height: 100%;*/
  padding: 0 0 0 232px;
  /* float: right; */
  position: inherit;
}
.indicate{
  width:1%;
  height:54%;
  padding-right:40px;
  padding-top:23%;
  float:right !important;
}

.indicateOn{
  width:12px;
  height:12px;
  float:left;
}

.indicateOff{
  width:12px;
  height:12px;
  float:left;
}

.contents-left-section1{
  /*width: 360px;*/
  width:397px;
  /*float:left;*/
  padding-top: 100px;
  
}
.contents-left{
  width: 360px;
  float:left;

}
a {
  text-decoration: none;
  color: rgb(25, 25, 25);
}

.contents-text{
  width:417px;
  height: 100%;
  padding-top:260px;
  word-break: keep-all;
  text-align: left;
}

.contents-text h1{
  font-family:'Noto Serif KR', sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;

}
.contents-base {
  width: 417px;
}
.contents-coupon {
  width: 436px;
  padding: 140px 0;
}
.contents-coupon h1 {
  font-family:'Noto Serif KR', sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}
.contents-text-black {
  width:100%;
  float: left;
  padding-top: 260px;
  text-align: left;
}
.contents-text-black h1{
  font-family:'Noto Serif KR', sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #191919;
  text-align: left;
}
.bold  {
  font-weight: 900;
  text-align: left;
  font-family:'Noto Serif KR', sans-serif;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff;
}

.contents-text h2{
  font-family:'Noto Serif KR', sans-serif;
  font-size:32px;
  Line-height:1.38;
  font-weight: 600;
  color:#000000;
}
.contents-text h3{
  font-family:'Noto Serif KR', sans-serif;;
  font-weight: 400;
  font-size:16px;
  line-height: 1.63;
  opacity: 0.7;
  padding-top:40px;
  color:#000000;
  width: 92%;
  float: left;
  font-weight: 300;
}

.red{
  color:#ff5543;
}

.white{
  color:#ffffff;
}

.contents-right{
  width:417px;
  float:right;
  text-align: center;
  vertical-align: middle;
  display:table-cell;
  padding: 0;
  margin: 0 auto;
}

.contents-right-section1{
  /*width: 760px;*/
  /*width: 100%;*/
  height: 100%;
  /*overflow: auto;*/
  /*float:right;*/
  text-align: center;
  vertical-align: middle;
  /*display:table-cell;*/
  margin: 0 auto;
  /*position: relative;*/
  overflow-x: hidden;
  overflow-y: hidden;
}

.section1img {
  padding-top: 160px;
  width: 100%;
  height: 100%;
  background-image: url('../img_update/tablet.png');
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.appdownload{
  display: none !important;
}

.size{
  max-width:1920px;
  width: 100%;
  height:100%;
}

.link{
  cursor: pointer;
}

img { 
  border:0 
}

.terms{
  max-width: 600px;
  margin:0 auto;
  padding: 20px;
}

.terms h2{
  margin-bottom: 20px;
  font-size: 1.375rem;
  line-height: 1;
  font-weight:600;
}

.terms h3{
  margin-top:20px;
  margin-bottom:10px;
  line-height:1;
  font-size:15px;
  font-weight:600;
}

.terms h4{
  font-weight:500;
  font-size:14px;
  float:left;
}

.terms p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: .8125rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: .8125rem;
}

.ol{
  list-style-type: none;
}

.ul{
  list-style-type: circle
}

.table{
  text-align:left;
  border-bottom:  0.5px solid #ddd;
}

th, td{
  text-align: left;
  border: 0.5px solid #ddd ;
  padding: 7px 10px;
}

table {
  width: 100%;
  text-align: center;
  border: 0.5px solid #ddd;
  border-bottom:  0.5px solid #ddd;
  border-collapse: collapse;
}


/* display: none*/
.h3-mobile ,.contents-mobile, .mobile-sidebar, .mobile-slidebar, .tablet, .mobile, .event, .tablet_section1{
  display:none;
}
