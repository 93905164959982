@import url("../base.css");

.section2{
    width:100%;
    height:867px;
    background-color:#2c2c2c;
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
}


.sectionimg1{
    /*width:inherit;*/
    width:360px;
    height:607px;
    padding:120px 0 140px 0;
}

.select-contents {
    width: 100%;
    float: left;
    padding-top: 50px;
}
.select-type {
    width: 68px;
    height: 36px;
    float: left;
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}
.select-type p {
    margin: 5px 8px 5px 8px;
}
.select-type-line {
    display: none;
    margin-top: 4px;
    width: 58px;
    height: 1px;
    border: solid 1px #ffffff;
    background-color: #ffffff;
}

.data2img {
    width: 360px;
    height: 100%;
    position: relative;

}
