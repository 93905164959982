@import url("../base.css");
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+KR:200,300,400,500,600,700,900&subset=korean');

.wrap{    
    width:100%;
    height:100%;
    padding-bottom: 60px;
}


.header{
    width:100%;
    height: 60px;
    background-color:#fafafa;
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:700; 
    text-align: center;
    border-left-color: #dddddd;
    border-right-color: #dddddd;
    position: fixed !important;
    display:flex;
    z-index :99999;   
}


.mus_logo{    
    float:center;
    width:81px;
    height:30px;
    margin:0 auto;
    text-align: center;
    padding: 15px 0px 15px 30px;
    cursor: pointer;
}

.logo{
    float:left;
}
.navbar_contents {
    max-width: 1920px;
    width: 100%;
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
    border-top: solid #eeeeee 0.5px;
}
.navbar{
    /*width:1920px;*/
    max-width: 1024px;
    height:100%;
    margin:0 auto;
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:700; 
    /*float:right;*/
    text-align:center;

    
    
}
.menu{
    height:100%;
    margin:0 auto;
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:700; 
    float:right;
    text-align:center;
}

.menu li{
    float:left;
    list-style:none;
    padding: 20px 40px 20px 0px;
    font-size: 14px;
    font-family: 'NotoSansCJKkr', sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
}

.header-nav{
    float:right;
    font-size:16px;
    color: #000000;
    margin:0 auto;
    padding: 0.5%;
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:700; 
    padding-right:2%;
}