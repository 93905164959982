  /*!* Device = mobile , Screen = 414px to 767px *!*/
  @media (min-width: 414px) and (max-width: 767px) {
      .lottieSize {
          width: 280px !important;
          height: 473px !important;
      }
      .lotteSize4 {
          width: 280px !important;
          height: 280px !important;
      }
      .section1 {
          height: 692px !important;
          background-image: url('../../img_update/bg-1-mobile-767@3x.png') !important;
          background-size: 767px 692px  !important;
          width: 100%;
      }
      .navbar_contents {
          border-right: 0 ;
          border-left: 0;
          border-top: 0;
      }
          .wrap {
              padding-bottom: 56px !important;
          }
          .section1 .contents {
              max-width: 767px !important;
              height: 172px;
              width: 100% ;
          }
          .size{
              max-width:767px !important;
              width: 100%;
              height: 100%;
          }
          .header{
              width:100%;
              height: 56px;
              background-color:#fafafa;
              text-align: center;
              border-right: 0;
              border-left: 0;
          }
          .logo{
              padding: 16px 20px 17px 20px;
              z-index: 1;
              position: absolute;
          }

          .mus_logo{
              float:left;
              width:63px !important;
              height:23px !important;
              margin:0 auto;
              padding:0;
          }
          .navbar{
              width:100% !important;
              height:100%;
              margin:0 auto;
              font-family:  'NotoSansCJKkr', sans-serif;
              font-weight:700;
              /*float: center;*/
              text-align:center;
              border-bottom: 0 !important;
          }
          .menu{
              display:none !important;
          }
          .menu li{
              float:left;
              list-style:none;
              line-height: 100%;
              padding: 34px 60px 34px 0;
          }
          .header-nav{
              float:right;
              font-size:16px;
              color: #000000;
              margin:0 auto;
              padding: 0.5%;
              font-family:  'NotoSansCJKkr', sans-serif;
              font-weight:700;
              padding-right:2%;
          }
          /* mobile section */
          .section-contents-section1 {
              width: 100%;
              /*height: 100%;*/
              padding: 0 !important;
          }
          .contents {
              height: 100%;
          }

          .contents-left-section1 {
              /*padding-top: 40px;*/
              width: 280px;
              padding: 40px 20px 0 20px !important;
              float: left;
          }
          .section1 {
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .section1 .top-text {
              font-size: 24px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #191919;
              padding-left: 0;
          }
          .section1 .top-contetns {
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              color: #7f7f7f;
              padding-top: 20px;
              text-align: left;
          }
          .top-app {
              display: none;
          }
          .contents-right-section1 {
              /*width: 380px;*/
              height: 380px;
              width: 100%;
              overflow: auto;
              overflow-x: hidden;
              overflow-y: hidden;
          }

          .sectionimg {
              width: 380px !important;
              height: 380px !important;
              margin-top: 20px !important;
              background-size: 380px 380px !important;
              background-repeat: no-repeat;
          }

          .section2 {
              width: 100%;
              height: 821px !important;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .section-contents {
              width: 280px;
              height: 100%;
          }
          .tablet {
              display: block !important;
          }
          .contents-right {
              width: 100%;
              height: 100%;
          }
          .tablet .contents-right {
              width: 100%;
              height: 100%;
              float: left;
              margin: 0 auto;
          }
          .contents-text {
              padding-top: 0px;
              text-align: center;
              width: 100%;
              float: left;
              padding-top: 60px;
          }
          .contents-text h1 {
              width: 100%;
              text-align: center;
              font-size: 24px !important;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #ffffff;
          }

          .section2 .contents-text p {
              width: 100%;
              padding: 20px 0 20px 0 !important;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: center;
              color: #cccccc;
          }
          .contents-text p {
              width: 100%;
              padding: 20px 0 ;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: center;
              color: #cccccc;
          }
          .contents-left {
              width: 100%;
              /*height: 361px;*/
          }
          .section2 .contents-left {
              margin-bottom: 20px;
          }
          .sectionimg1 {
              /*width: 190px !important;*/
              /*height: 361px !important;*/
              width: 100% !important;
              height: 100% !important;
          }
          .thumbs-up {
              width: 100%;
              height: 100%;
              padding: 0 !important;
          }
          .tablet .select-contents-type-box {
              width: 224px;
              height: 36px;
              padding-top: 20px;
              float: left;
          }
          .select-contents-type{
              width: 280px;
              height: 100%;
              margin: 0 24px;
          }
          .select-contents-padding {
              padding: 0 10px;
          }
          .select-type p {
              padding: 0;
          }
          .select-type  {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: center;
              color: #ffffff;
          }
          .section3 {
              width: 100%;
              height: 580px !important;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .section3 .contents-left {
              height: 0 !important;
          }

          .desktop, .tablet_section1 {
              display: none !important;
          }
          .section3 .contents-top-title {
              padding-top: 60px !important;
          }
          .section3 .tablet {
              display: none !important;
          }

          .contents-top-title h1 {
              font-size: 24px !important;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33 !important;
              letter-spacing: normal;
              text-align: center;
              color: #191919;
          }
          .contents-top-text {
              padding: 20px 0 !important;
          }
          .contents-top-text p {
              font-size: 14px !important;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71 !important;
              letter-spacing: normal;
              text-align: center;
              color: #7f7f7f;
              padding-left: 0 !important;
          }
          .contents-img {
              width: 100%;
              height: 100% !important;
          }
          .data2img {
              width: 100% !important;
              height: 353px !important;
              padding-top: 20px;
          }
          .section4 {
              width: 100%;
              height: 765px !important;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .contents-text-black {
              padding-top: 0 !important;
          }
          .contents-base {
              width: 100%;
          }
          .contents-base h1 {
              padding-top: 60px;
          }
          .contents-text-black h1 {
              font-size: 24px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              text-align: center;
              color: #191919;
          }
          .top-contetns {
              padding: 20px 0 !important;
              padding-left: 0 ;
              font-size: 14px !important;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71 !important;
              letter-spacing: normal;
              text-align: center;
              color: #7f7f7f;
          }
          .tablet .contents-left {
              width: 100%;
              height: 100% !important;
              float: left;
          }

          /* none */
          .desktop_tablet {
              display: none;
          }
          .mobile {
              display: block;
              width: 100%;
              float: left;
          }
          .sectionimg4 {
              width: 100% !important;
              height: 100% !important;
              /*padding-top: 20px !important;*/
          }
          .section5 {
              width: 100%;
              height: 592px !important;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .section5 .contents-left {
              height: 192px !important;
          }
          .sectionimg5 {
              width: 100% !important;
              height: 100% !important;
          }
          .section5 .contents-right {
              height: 280px !important;
              float: left;
              padding-top: 40px;
          }
          .section6 {
              display: none;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .section7 {
              height: 365px !important;
              background-size: cover;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .contents-mus-text {
              padding-top: 60px !important;
          }
          .appicon {
              width: 77px !important;
              height: 77px !important;
          }
          .contents-mus-text h4 {
              font-family:  'NotoSansCJKkr', sans-serif;
              padding: 20px 0 0 0 !important;
              font-size: 16px !important;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5 !important;
              letter-spacing: normal;
              text-align: center;
              color: #191919;
          }
          .contents-app-down {
              display: none;
          }
          .footer {
              width: 100%;
              height: 569px !important;
              border-left: 0 !important;
              border-right: 0 !important;
          }
          .footer-text {
              width: 100% !important;
              float: left;
              margin: 20px 0 0 0 !important;
          }
          .footer-desktop {
              display: none !important;
          }
          .footer-mobile {
              width: 100%;
              float: left;
              margin-bottom: 20px;
              display: block !important;
          }
          .footer-contents {
              width: 100% !important;
              height: 512px !important;
          }
          .footer-contents-box {
              float: left;
          }
          .footer-title-text {
              margin: 0 0 0 0 !important;
          }
          .footer-contents-info {
              /*width: 414px !important;*/
              width: 100% !important;
              float: left;
              padding: 50px 0 0 20px !important;
              height: 412px;
              /*margin: 0 20px 0 20px;*/
          }
          .user h5 {
              font-size: 12px !important;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33 !important;
              letter-spacing: normal;
              color: #191919;
              width: 280px;
              padding-bottom: 20px;
          }
          .adress_and_moditt {
              width: 280px !important;
              padding: 15px 0 35px 0 !important;
              height: 116px !important;
          }
          .adress_moditt {
              width: 100% !important;

          }
          .adress_moditt h5 {
              font-size: 12px !important;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height:16px !important;
              letter-spacing: normal;
              color: #9a9a9a;
              width: 280px;
          }
          .adress {
              padding-left: 0 !important;
              width: 100% !important;
              padding: 20px 0 35px 0;
          }
          .adress h5 {
              font-size: 12px !important;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 16px !important;
              letter-spacing: normal;
              color: #9a9a9a;
              width: 280px;
          }
          .copyright {
              max-width: 747px !important;
              word-break: keep-all;
          }
          .sns_link {
              width: 280px !important;
          }
          .snsBox {
              width: 35px !important;
              height: 35px !important;
              border-radius: 10.9px !important;
              background-color: #9a9a9a;
          }
          .sns {
              width: 20px !important;
              height: 20px !important;
              margin: 8px 7px 7px 8px !important;
              border-radius: 0 !important;
          }
          .blog {
              width: 18px !important;
              height: 18px !important;
          }
          .event {
              width: 100%;
              height: 57px;
              padding: 0;
              margin: 0 auto;
              position: fixed;
              background-color: #07cd9f;
              bottom: 0;
              display: block !important;
          }
          .event_box {
              margin: 0 20px;
              height: 57px;
              max-width: 727px;
          }
          .event_text {
              padding: 15px 0;
              width: 163px;
              height: 27px;
              float: left;
          }
          .event_text p {
              font-family:  'NotoSansCJKkr', sans-serif;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #ffffff;
              text-align: left;
              padding-left: 0 !important;
              word-break: keep-all;
          }
      .event_down,
      .event_down:active,
      .event_down:focus{
          opacity: 1 !important;
          width: 80px;
          height: 30px;
          border-radius: 6px;
          border: solid 1px #ffffff !important;
          float: right;
          margin: 15px 0;
          cursor: pointer;
      }
      .event_down:hover {
          opacity: 0.7;
          border-radius: 6px;
          border: solid 1px #ffffff !important;
      }
          .event_down p {
              font-family:  'NotoSansCJKkr', sans-serif;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: center;
              color: #ffffff;
              padding: 5px 13px 5px 14px;
          }

          .mobile_app_download {
              background:url('../../img_update/downlod-btn-mobile.svg');
              background-repeat: no-repeat;
              width: 118px;
              height: 40px;
              margin-top: 40px;
              cursor: pointer;
          }
          .mobile_app_download:hover {
              background:url('../../img_update/downlod-btn-mobile-hover.svg');
              background-repeat: no-repeat;
          }



  }
