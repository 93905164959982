@media (min-width: 0px) and (max-width: 767px) {

    * {
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 0 !important;
    }

    .mobile-slidebar {
        display: block;
    }

    .desktop-menu {
        float: right;
        width: 100%;
        height: 100%;
        position: absolute;
    }

      .menubtn{
        background:none;
        /*position:absolute;*/
        float: right;
        top:0;
        left:0;
        line-height:45px;
        color:#999;
        cursor:pointer;
        width:24px;
        height:24px;
        text-align:center;
        padding:16px 20px;
      }
      .cross{
        background:none;
        position:absolute;
        top:0;
        right:0;
        color:#999;
        cursor:pointer;
        width:24px;
        height:24px;
        text-align:center;
        padding:16px 20px;
          z-index: 1;
      }

    .menulist_close{
        opacity: 0;
        -moz-opacity:0; /* Firefox and Mozilla browsers */
        -webkit-opacity: 0; /* WebKit browser e.g. Safari */
        filter: alpha(opacity=0); /* For IE8 and earlier */
        visibility: hidden;
        border-left: 0 !important;
        border-right: 0 !important;
        transition: .3s ease-out;
        background-color: #fafafa;
        font-size: 14px;
        text-align:left;
        font-family: 'NotoSansCJKkr', sans-serif;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        height:228px;
        z-index: 1000;
    }

      .menulist{
        opacity: 1;
        -moz-opacity:1; /* Firefox and Mozilla browsers */
        -webkit-opacity: 1; /* WebKit browser e.g. Safari */
        filter: alpha(opacity=10); /* For IE8 and earlier */
        /*visibility: visible;*/
        border-left: 0 !important;
        border-right: 0 !important;
        /*transition: 300ms;*/
        /*-webkit-transition:  opacity .5s;*/
        /*!*-webkit-transition: .3s;*!*/
        /*-webkit-transition: -webkit-transform .3s linear;*/
        background-color: #fafafa;
        font-size: 14px;
        text-align:left;
        font-family:  'NotoSansCJKkr', sans-serif;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        height:228px;
        z-index: 9999;
        display: block;
        }

      .menulist ul {
        transition: opacity .5s;
        margin: 0;
        height: 100%;
        list-style-type: none;
        list-style-image: none;
        padding: 0 20px;
        display: block;
        }

      .menulist li {
        display: block;
        cursor: pointer;
        }

      .menulist ul li a {
        text-decoration:none;
        margin: 0;
        color:#000000;
        }
      .menulist ul li a:hover {
        color: #000000;
        text-decoration:none;
        }
      .menulist a{
        text-decoration:none;
        color:#000000;
        }
      .menulist a:hover{
        text-decoration:none;
        color:#000000;
        }

    .menulist_close ul {
        margin: 0;
        height: 100%;
        list-style-type: none;
        list-style-image: none;
        padding: 0 20px;

    }
    .menulist_close li {
        display: block;
        cursor: pointer;
    }

    .menulist_close ul li a {
        text-decoration:none;
        margin: 0px;
        color:#000000;
    }
    .menulist_close ul li a:hover {
        color: #000000;
        text-decoration:none;
    }
    .menulist_close a{
        text-decoration:none;
        color:#000000;
    }
    .menulist_close a:hover{
        text-decoration:none;
        color:#000000;
    }
      .about{
        height: 20px;
        width: 100%;
        padding: 78px 0 0 0;
      }
      .pay{
        padding: 25px 0;
      }
      .master {
        width: 100%;
        padding: 0;
      }

      .glyphicon-home{
        color:white;
        font-size:1.5em;
        margin-top:5px;
        margin:0 auto;

      }

      header{display:inline-block; font-size:12px;}
      p {
        padding-left:30px;
      }
      a{color:#336699;}


      .menulist hr{
        width:90%;
        float:center;
      }

    .menulist_close hr{
        width:90%;
        float:center;
    }

      /*display : none */
      .menu{
          display:none;
      }


    }
