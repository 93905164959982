@import url("../base.css");

.section6{
    width:100%;
    height:436px;
    /*background-image: url('../../img_update/bg-2-event-desktop-1920.png');*/
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2c2c2c;
}

.size{
    max-width:1920px;
    width: 100%;
    height:100%;
}
.contents-top{
    width:100%;
}

.contents-mus{
    width: 57%;
    height:100%;
}
.contents-mus-text{
    text-align: center;
    padding-top:80px;
}

h4{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:500; 
    font-size: 18px;
    line-height: 1.44;
    text-align: center;
    color: #000000;
    padding: 20px 20px;
    
}

.appicon{
    width:140px;
    height:140px;
    box-shadow: 0 4px 9px 0 rgba(162, 162, 162, 0.5);
    border-radius: 24px;
}



.contents-app-text{
    text-align: center;
    
}
.appstore-bottom, .googleplay-bottom{    
    width: 186px;
    height: 46px;
    padding: 30px 10px 30px 10px;
    text-align:center;
}

.coupon_img {
    padding: 47px 0 39px 0;
}