@import url("../base.css");

.section1{
    width:100%;
    height:1100px;
    background-image: url('../../img_update/bg-1-desktop-1920.svg');
    background-size: 1920px 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
    position: static;
}


.section1_bgimg{
    background-size: 1920px 100%;
}

.contents{
    width:100%;
    /*height:90%;*/
}
.contents-text-top{
    word-break: keep-all;
  }

.top-app{
    width:100%;
    padding-top:44px;
    float: left;
}
.googleplay_button {
    background:url('../../img_update/google-btn.svg');
    background-repeat: no-repeat;
}
.googleplay_button:hover{
    background:url('../../img_update/google-btn-hover.svg');
    background-repeat: no-repeat;
}
.appstore_button {
    background:url('../../img_update/appstore-btn.svg');
    background-repeat: no-repeat;
}
.appstore_button:hover{
    background:url('../../img_update/appstore-btn-hover.svg');
    background-repeat: no-repeat;
}

.appstore,
.googleplay{
    float:left;
    width:189px;
    height:52px;
    /*padding-right:10px;*/
    cursor: pointer;
}
.button_padding {
    /*margin-left: 20px;*/
    float: right;
}

  

.sectionimg {
    /*width: 760px;*/
    width: 100%;
    height: 760px;
    margin-top: 160px;
    background-image: url("../../img_update/tablet@3x.png");
    background-size: 760px 760px;
    background-repeat: no-repeat;
}

.top-text{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:700;
    font-size:40px;
    Line-height:48px;
    font-stretch: normal;
    font-style: normal;
    color:#191919;

}

.top-title{
    font-family: 'NotoSansCJKkr', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color:#191919;
}

.top-contetns{
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    color: #7f7f7f;
    padding-top: 35px;
}

.top-contetns-white{
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    color: #ffffff;
    padding-top: 35px;
}



.appdownload{
    display:none;
}