@import url("../base.css");

.section3{
    width:100%;
    height:823px;
    background-color:#eeeeee;
    border-left: solid #eeeeee 0.5px;
    border-right: solid #eeeeee 0.5px;
    position: relative;
    z-index: -1;
}

.sectionimg2{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;


}
.contents-top-title {
    padding-top: 120px;
    width: 100%;
    float: left;
}
.contents-top-title h1 {
    font-family: 'Noto Serif KR', sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #191919;
}
.contents-top-text {
    width: 100%;
    float: left;
    padding: 35px 0;
}
.contents-top-text p {
    font-family:  'NotoSansCJKkr', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: center;
    color: #7f7f7f;
    z-index: 1;
}

.contents-img {
    /*height: 823px;*/
    height: 823px;


}